'use client';
import React from 'react';
import {
  mediumCardsWithIconsSectionData,
  MediumCardsWithIconsDataByPageName,
} from '@/data/static/ts/mediumCardsWithIconData';
import PrimaryContainer from '@/components/global/containers/PrimaryContainer';
import PrimaryGreenButton from '@/components/global/buttons/PrimaryGreenButton';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import DarkHeading2 from '@/components/global/typography/heading/Heading2/DarkHeading2';
import PrimaryGreenButtonLink from '@/components/global/buttons/PrimaryGreenButtonLink';
import MediumCardWithIcon from '@/components/common/sections/MediumCardsWithIconSection/MediumCardWithIcon';
import StyledMediumCardsWithIconSection from '@/components/common/sections/MediumCardsWithIconSection/style';

interface IMediumCardsWithIconSectionProps {
  page: MediumCardsWithIconsDataByPageName;
}

const MediumCardsWithIconSection = ({
  page,
}: IMediumCardsWithIconSectionProps) => {
  const { title, text, cardsData, linkUrl, buttonText, buttonAction } =
    mediumCardsWithIconsSectionData[page];

  return (
    <PrimaryContainer className={''}>
      <StyledMediumCardsWithIconSection>
        <div className={'how-to-use-content'}>
          <DarkHeading2 className={`content-title ${!text ? 'mb-10' : ''}`}>
            {title}
          </DarkHeading2>
          {text && (
            <MainParagraph className={'content-text text-center'}>{text}</MainParagraph>
          )}
        </div>
        <div className={'cards-wrapper'}>
          {cardsData.map((cardItem) => {
            return (
              <MediumCardWithIcon
                key={cardItem.id}
                title={cardItem.title}
                text={cardItem.text}
                icon={cardItem.icon}
              />
            );
          })}
        </div>
        {buttonText && (
          <div className={'assessment-btn-wrapper'}>
            {linkUrl ? (
              <PrimaryGreenButtonLink
                className={'assessment-btn'}
                onClick={buttonAction}
                href={linkUrl}
              >
                {buttonText}
              </PrimaryGreenButtonLink>
            ) : (
              <PrimaryGreenButton
                onClick={buttonAction}
                className={'assessment-btn'}
              >
                {buttonText}
              </PrimaryGreenButton>
            )}
          </div>
        )}
      </StyledMediumCardsWithIconSection>
    </PrimaryContainer>
  );
};

export default MediumCardsWithIconSection;
