'use client';

import React from 'react';
import MenuLink from '@/components/global/typography/links/MenuLink';
import SolutionsDropdown from '@/components/common/header/DesktopMenu/SolutionsDropdown';
import ResourcesDropdown from '@/components/common/header/DesktopMenu/ResourcesDropdown';

const MainMenu: React.FC = () => {
  return (
    <div className={'flex ml-20'}>
      <SolutionsDropdown />
      <MenuLink href={'/pricing'} className={'mr-12'}>
        Pricing
      </MenuLink>
      <ResourcesDropdown />
      <MenuLink href={'/for-labs'} className={'mr-12'} style={{color: '#E95D53'}}>
        For Labs
      </MenuLink>
    </div>
  );
};

export default MainMenu;
