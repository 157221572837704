import { AIDoctorsCardsData } from '@/data/static/ts/AIDoctor';
import { fantasticCardsData } from '@/data/static/ts/AIHealthAssistant';
import { SIGN_UP_URL } from '@/utils/config';
import { benefitCardsData } from '@/data/static/ts/SecondOpinion';
import { HomeServicesData } from '@/data/static/ts/HomeServices';
import { LabPartnershipBenefits } from '@/data/static/ts/LabPartnershipBenefits';

export const mediumCardsWithIconsSectionData = {
  home: {
    title: 'Benefits of Personal AI Health Assistant',
    text: 'We have all you need to better understand your health and make informed and accurate decisions about your well-being.',
    linkUrl: SIGN_UP_URL,
    buttonAction: undefined,
    buttonText: 'Try Docus for Free',
    cardsData: HomeServicesData,
  },
  'lab-test-partnership': {
    title: 'Unlock Benefits for Your Lab',
    text: "Integrating Docus AI Lab Software into your workflow transforms your patients' experience and delivers major benefits to your lab.",
    linkUrl: SIGN_UP_URL,
    buttonAction: undefined,
    buttonText: 'Explore AI Doctor',
    cardsData: LabPartnershipBenefits,
  },
  'ai-doctor': {
    title: 'Benefits of Docus AI Doctor',
    text: '',
    linkUrl: SIGN_UP_URL,
    buttonAction: undefined,
    buttonText: 'Explore AI Doctor',
    cardsData: AIDoctorsCardsData,
  },
  'ai-health-assistant': {
    title: 'Why it’s Fantastic',
    text: '',
    linkUrl: SIGN_UP_URL,
    buttonAction: undefined,
    buttonText: 'Try Docus for Free',
    cardsData: fantasticCardsData,
  },
  'second-opinion': {
    title: 'Benefits for patients & caregivers',
    text: '',
    linkUrl: '/doctors',
    buttonAction: undefined,
    buttonText: 'Explore Top Doctors',
    cardsData: benefitCardsData,
  },
};

export type MediumCardsWithIconsDataByPageName =
  keyof typeof mediumCardsWithIconsSectionData;
