import { MouseEventHandler } from 'react';
import { SIGN_UP_URL } from '@/utils/config';
import { Analytics } from '@/libs/GoogleAnalytics';

const handleAIHealthAssistantPageButtonAction: MouseEventHandler = (e) => {
  e.stopPropagation();
  Analytics.sendSignUpStartEventToGA();
};

const handleAIDoctorPageButtonAction: MouseEventHandler = (e) => {
  e.stopPropagation();
  Analytics.sendSignUpStartEventToGA();
};

export const advancedTextImageSectionData = {
  'lab-test-interpretation': {
    title: 'Unlock the Power of Docus AI Lab Analysis',
    sectionData: [
      {
        title: 'In-depth Biomarker Analysis',
        description:
          'Our AI system simplifies complex lab data into clear, easy-to-understand visual interpretations:',
        imageUrl: '/lab-test-interpretation/biomarkers.png',
        imageAlt: 'Biomarker Overview and Trends - Docus AI Lab Software',
        reversed: false,
        hasSubContent: true,
        buttonText: 'Explore Your Biomarkers',
        linkUrl: SIGN_UP_URL,
        buttonAction: undefined,
        subContentItems: [
          {
            id: 1,
            number: '1',
            title: 'Detailed Biomarker Information ',
            text: "See each biomarker's measured value, normal range, and status (normal, high, or low).",
          },
          {
            id: 2,
            number: '2',
            title: 'In-depth Explanations',
            text: 'Receive explanations for out-of-range values, including potential health risks and related conditions.',
          },
          {
            id: 3,
            number: '3',
            title: 'Trend Tracking',
            text: 'Monitor biomarker changes over time with dynamic graphs that highlight trends from various tests.',
          },
        ],
      },
      {
        title: 'Detailed Lab Test Reports',
        description:
          'Receive detailed lab test reports for better understanding and proactive health management. Each report includes:',
        imageUrl: '/lab-test-interpretation/lab-test-reports.png',
        imageAlt: 'Lab Test Interpretation Report - Docus AI Lab Software',
        reversed: true,
        hasSubContent: true,
        buttonText: 'Get Your Report',
        linkUrl: SIGN_UP_URL,
        buttonAction: undefined,
        subContentItems: [
          {
            id: 1,
            number: '1',
            title: 'Test Interpretation',
            text: 'Understand the meanings behind your test results with clear explanations.',
          },
          {
            id: 2,
            number: '2',
            title: 'Clinical Significance',
            text: 'Learn about the implications of your results and their potential impact on your health.',
          },
          {
            id: 3,
            number: '3',
            title: 'Recommendations',
            text: 'Receive actionable advice based on your lab results to help manage and improve your health.',
          },
        ],
      },
      {
        title: 'Interaction with AI Doctor',
        description:
          'Consult our AI Doctor to clarify any questions about your lab results. It provides:',
        imageUrl: '/lab-test-interpretation/interaction-with-ai-doctor.png',
        imageAlt: 'Follow-up Test Notifications - Docus AI Lab Software',
        reversed: false,
        hasSubContent: true,
        buttonText: 'Talk to AI Doctor',
        linkUrl: '/ai-doctor',
        buttonAction: undefined,
        subContentItems: [
          {
            id: 1,
            number: '1',
            title: 'Detailed Explanations',
            text: 'Get clear answers to any additional questions about your lab tests, biomarker analysis, or lab test report.',
          },
          {
            id: 2,
            number: '2',
            title: 'Tailored Advice',
            text: 'Get practical steps and strategies tailored to improve your health.',
          },
          {
            id: 3,
            number: '3',
            title: 'Specialist Referrals',
            text: 'Access our network of 350+ top doctors for further expert consultation if needed.',
          },
        ],
      },
    ],
  },
  'ai-doctor': {
    title: 'Transforming Healthcare with AI Doctor',
    sectionData: [
      {
        title: 'Personalize Your Health Journey',
        description:
          'Complete your health profile to unlock a customized AI Doctor experience, tailored to your health needs.',
        imageUrl: '/ai-doctor/generate-health-reports.png',
        imageAlt: 'Get treatment options',
        reversed: false,
        hasSubContent: true,
        buttonText: 'Start Journey',
        linkUrl: SIGN_UP_URL,
        buttonAction: undefined,
        subContentItems: [
          {
            id: 1,
            number: '1',
            title: 'Chat with Your Personal AI Doctor',
            text: 'Chat with an AI that knows your health history and provides responses and recommendations based on your personal health data.',
          },
          {
            id: 2,
            number: '2',
            title: 'Discover Your Health Risks',
            text: 'Identify potential health risks through an analysis of your health profile. Gain valuable insights with detailed explanations and recommendations.',
          },
          {
            id: 3,
            number: '3',
            title: 'Receive a Personal Checkup Plan',
            text: 'Based on your health data and identified risks, receive a customized checkup plan recommending key tests and screenings for proactive health management.',
          },
        ],
      },
      {
        title: 'Consult Your AI Doctor',
        description:
          'Share your symptoms, health condition, and any health-related questions while chatting with your AI Doctor.',
        imageUrl: '/ai-doctor/ai-doctor-features.png',
        imageAlt: 'Generate health reports',
        reversed: true,
        hasSubContent: true,
        buttonText: 'Start Chat',
        linkUrl: SIGN_UP_URL,
        buttonAction: undefined,
        subContentItems: [
          {
            id: 1,
            number: '1',
            title: 'Find Probability of Diseases (DDx)',
            text: 'Receive estimated probabilities of conditions that guide you toward understanding potential diagnoses.',
          },
          {
            id: 2,
            number: '2',
            title: 'Explore Treatment Options',
            text: 'Receive information on potential treatment options and other health recommendations to discuss with your doctor.',
          },
          {
            id: 3,
            number: '3',
            title: 'Generate Health Reports',
            text: 'Generate detailed reports from AI interactions to enhance discussions with your doctor.',
          },
        ],
        noteText:
          'Note: These AI insights are for informational purposes, meant to support, not replace, medical diagnosis, treatment, or consultation.',
      },
      {
        title: 'Validate Your Recommendations',
        description:
          'Continue your health journey. Validate your potential diagnosis and treatment strategy with top doctors from the US and Europe.',
        imageUrl: '/ai-doctor/validate-your-recommendations.png',
        imageAlt: 'Validate your recommendations',
        reversed: false,
        hasSubContent: true,
        buttonText: 'View Doctors',
        linkUrl: '/doctors',
        buttonAction: undefined,
        subContentItems: [
          {
            id: 1,
            number: '1',
            title: 'Identify the Right Specialty',
            text: 'Based on your health profile and conversations, your AI Doctor identifies the most relevant medical specialties for your condition.',
          },
          {
            id: 2,
            number: '2',
            title: 'Get Doctor Recommendations',
            text: 'Dr. AI recommends the most appropriate doctors from our extensive network of over 350 professionals for consultation.',
          },
          {
            id: 3,
            number: '3',
            title: 'Request a Consultation',
            text: 'Select an expert for a second opinion on your health condition and request a consultation through written feedback or a video call.',
          },
        ],
      },
    ],
  },
  'lab-test-partnership': {
    title: 'Bridging the Gaps with Docus AI Lab Software',
    sectionData: [
      {
        title: 'Biomarker Overview',
        description: '',
        imageUrl: '/lab-test-interpretation/biomarkers.png',
        imageAlt: 'Docus AI Lab Software',
        reversed: false,
        hasSubContent: true,
        subContentItems: [
          {
            id: 1,
            number: '1',
            title: 'Visual charts',
            text: 'for each biomarker to enhance understanding.',
          },
          {
            id: 2,
            number: '2',
            title: 'Reference ranges',
            text: 'for easy comparison and clarity.',
          },
          {
            id: 3,
            number: '3',
            title: 'Trend tracking',
            text: 'to monitor biomarker changes over time.',
          },
          {
            id: 4,
            number: '4',
            title: 'Clear explanations',
            text: "of each biomarker's significance and role in health.",
          },
        ],
      },
      {
        title: 'Lab Results Analysis & Insights',
        description: '',
        imageUrl: '/lab-test-interpretation/lab-test-reports.png',
        imageAlt: 'Lab Test Results - Docus AI',
        reversed: true,
        hasSubContent: true,
        subContentItems: [
          {
            id: 1,
            number: '1',
            title: 'Comprehensive Test Interpretation',
            text: 'with actionable insights.',
          },
          {
            id: 2,
            number: '2',
            title: 'Highlighting abnormal findings',
            text: 'to identify areas of concern.',
          },
          {
            id: 3,
            number: '3',
            title: 'Follow-up test suggestions',
            text: 'for further investigation.',
          },
          {
            id: 4,
            number: '4',
            title: 'Specialist referrals',
            text: 'when advanced care is needed.',
          },
          {
            id: 5,
            number: '5',
            title: 'Lifestyle and dietary recommendations',
            text: 'to support better outcomes.',
          },
        ],
      },
      {
        title: 'Personalized Reminders',
        description: '',
        imageUrl: '/for-labs/follow_up.png',
        imageAlt: 'Personalized Reminders',
        reversed: false,
        hasSubContent: true,
        subContentItems: [
          {
            id: 1,
            number: '1',
            title: 'Flagging abnormal biomarkers',
            text: 'that require attention.',
          },
          {
            id: 2,
            number: '2',
            title: 'Optimized follow-up intervals',
            text: 'based on health insights.',
          },
          {
            id: 3,
            number: '3',
            title: 'Timely notifications',
            text: 'to ensure patients never miss critical follow-up tests.',
          },
        ],
      },
    ],
  },
};

export type AdvancedTextImageSectionDataByPageName =
  keyof typeof advancedTextImageSectionData;
