import {
  faChartLineUp,
  faRotateReverse,
  faUserPlus,
} from '@awesome.me/kit-b89bd90d95/icons/classic/regular';

export const LabPartnershipBenefits = [
  {
    id: 1,
    icon: faUserPlus,
    title: 'Attract New Patients',
    text: 'Deliver an exceptional patient experience with accurate test interpretations, clear explanations, and actionable insights, creating trust and improving satisfaction.',
  },
  {
    id: 2,
    icon: faRotateReverse,
    title: 'Improve Retention',
    text: 'Keep patients connected to your lab with personalized reminders, ensuring timely follow-ups and better long-term care.',
  },
  {
    id: 3,
    icon: faChartLineUp,
    title: 'Revenue Growth',
    text: 'Boost your lab’s profitability by maximizing patient acquisition and retention, driving consistent and sustainable growth.',
  },
];
